import { gql } from '@apollo/client';
export const LOGOUT = gql`
    mutation InternalSignOut{
        internalSignOut{
            status
        }
    }
`;

export const GET_CURRENT_USER_WITH_SIDE_NAV = gql`
    query CurrentUser{
        currentUser{
            id,
            userName,
            name,
            role{
                name,
                roleSideNavigations{
                    roleType,
                    sideNavigation{
                        id,
                        name,
                        icon,
                        order,
                        route,
                        parentId
                    }
                }
            }
        }
    }
`

export const FORGOT_PASSWORD = gql`
mutation ForgotPassword($email:String!){
    forgotPassword(email: $email){
        status
    }
}`

export const REGISTER = gql`
mutation SignUp($userName:String!,$password:String!, $clientId:String!){
    signUp(register:{userName: $userName, password: $password, clientId: $clientId}){
        id,
        userName,
        createdAt
    }
}
`

export const CREATE_USER_PROFILE = gql`
mutation CreateUserAccount($displayName:String!, $email:String!){
    createUserAccount(displayName:$displayName, email:$email){
        status
    }
}
`